import React, { useEffect, useState } from 'react';
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import { Button, CircularProgress, InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SearchIcon from '@material-ui/icons/Search';
import CustomisedModal from '../../components/customised-modal';
import { MODAL_WINDOW } from '../../constants/style';
import PermissionSetting from '../../components/permission-setting';
import LoadingPanel from '../../components/loading-panel';
import { imageURLPrefix } from '../../constants/settings';
import { useStyles } from '../../constants/style';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import VMCard from '../../components/collapsed-card';
import PermissionTemplate from '../../components/permission-template';

const UserPage = observer(() => {
  const { rootStore, collegeStore, userStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();
  const headingStyle = "text-barPrimary px-4 text-xl 2xl:text-2xl font-bold uppercase";
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentUserId, setCurrentUserId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [loginName, setLoginName] = useState('');
  const [imageName, setImageName] = useState('');
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [activeCollege, setActiveCollege] = useState(0);
  const [uid, setUid] = useState("");
  const [activedRole, setActivedRole] = useState(0);
  const [activedLevel, setActivedLevel] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [skip, setSkip] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState("");

  useEffect(() => {
    collegeStore.getCollegeBasicList();
    onCompleteEdit();
  }, []);

  useEffect(() => {
    userStore.getUserList(pageSize, skip);
  }, [skip]);

  const onChangeActiveCollege = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(event.target.value as number);

    setActiveCollege(event.target.value as number);
    if (showEdit == "create") {
      collegeStore.getPermissionListByCollege(event.target.value as number);
    }
  };

  const onChangeActivedRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(event.target.value as number);
    setActivedRole(event.target.value as number);
  };

  const onChangeActivedLevel = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(event.target.value as number);
    setActivedLevel(event.target.value as number);
  };

  const onChangePages = (direction: string) => {
    let tempSkip;
    if (direction == "forward") {
      if (skip + pageSize >= userStore.userList.total) return;
      tempSkip = skip + pageSize;
    } else {
      if (skip == 0) return;
      tempSkip = skip - pageSize;
    }
    setSkip(tempSkip);
  }

  const onDisplayAddUser = () => {
    onCompleteEdit();
    setShowEdit("create");
    // Check this is needed in the future
    // window.scroll({
    //     top: document.body.offsetHeight,
    //     left: 0,
    //     behavior: 'smooth'
    // });
  }

  const confirmUid = () => {
    userStore.mapSimonTeacher(firstName, surname)
      .then((response) => {
        if (response.length > 0) {
          setUid(response[0].uid);
          setEmail(response[0].email);
          setImageName(response[0].photo);
          collegeStore.getPermissionListByCollege(
            typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId
          );
          setActiveCollege(
            typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId
          );
          rootStore.notify("User Found!", 'success');
        } else {
          setUid("");
          setImageName("");
          rootStore.notify("User Not Found!", 'warning');
        }
      });
  }

  const displayDeleteModal = (id) => {
    setCurrentUserId(id);
    setShowModal(true);
  }

  const onDeleteUser = () => {
    userStore.updateUserStatus(currentUserId)
      .then(() => {
        userStore.getUserList(pageSize, 0);
        setSkip(0);
        rootStore.notify("User Deleted!", 'success')
      }).catch((err) => {
        rootStore.notify("Error: " + err, 'error');
      });
    setShowModal(false);
  }

  const onDisplayUserDetail = (id, reset?) => {
    if (!reset) onCompleteEdit();
    setCurrentUserId(id);
    let user = userStore.userList.data.filter(u => u.id == id);
    collegeStore.getPermissionListByCollege(user[0].collegeId).then(() => {
      setShowEdit("update");
    });
    userStore.setSelectedUser(user[0]);
    setFirstName(user[0].firstName);
    setSurname(user[0].lastName);
    setLoginName(user[0].username);
    setImageName(imageURLPrefix + user[0].simonUid ? imageURLPrefix + user[0].simonUid : "");
    setEmail(user[0].email);
    setMobile(user[0].mobile);
    setUid(user[0].simonUid);
    setPassword("******");
    setCPassword("******");
    setActiveCollege(user[0].collegeId);
    // Check this is needed in the future
    // window.scroll({
    //     top: document.body.offsetHeight,
    //     left: 0,
    //     behavior: 'smooth'
    // });
  }

  const createUser = () => {
    if (uid == "") {
      rootStore.notify("The user has not yet matched the simon database", 'warning');
      return;
    }
    if (loginName == "" || password == "" || cPassword == "" || activedRole == 0 ||
      activedLevel == 0 || mobile == "") {
      rootStore.notify("Please fill in all fields", 'warning');
      return;
    }
    if (cPassword != password) {
      rootStore.notify("The Two Passwords Do Not Matched!", 'warning');
      return;
    }

    console.log({
      firstName,
      lastName: surname,
      username: loginName,
      password: password,
      collegeId: typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId,
      permissionRole: activedRole,
      permissionLevel: activedLevel,
      simonUid: uid,
      mobile,
      email,
      status: 2,
    });
    userStore.createUser({
      firstName,
      lastName: surname,
      username: loginName,
      password: password,
      collegeId: typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId,
      permissionRole: activedRole,
      permissionLevel: activedLevel,
      simonUid: uid,
      mobile,
      email,
      status: 2,
    }).then(() => {
      userStore.sendResetPasswordEmail({
        email,
        status: 2,
      }).then(() => {
        rootStore.notify("User created! Validation email has sent to the user", 'success');
      }).catch(() => {
        rootStore.notify("User created! But validation email sent failed!", 'warning');
      }).finally(() => {
        userStore.getUserList(pageSize, 0);
        setSkip(0);
        onCompleteEdit();
      });
    }).catch((err) => {
      rootStore.notify("Error: " + err, 'error');
    });
  }

  const updateUser = () => {
    if (password == "" || cPassword == "" || mobile == "") {
      rootStore.notify("Please fill in all fields", 'warning');
      return;
    }
    if (cPassword != password) {
      rootStore.notify("The Two Passwords Do Not Matched!", 'warning');
      return;
    }
    userStore.updateUser({
      id: userStore.selectedUserInfo.id,
      firstName,
      lastName: surname,
      username: loginName,
      password: password == "******" ? userStore.selectedUserInfo.password : password,
      collegeId: activeCollege,
      mobile,
      email,
      simonUid: uid,
    }).then(() => {
      rootStore.notify("User Updated!", 'success');
      if (typeof window !== "undefined" && localStorage.USER_INFO) {
        if (uid == JSON.parse(localStorage.USER_INFO).uid) {
          userStore.signOut();
        } else {
          userStore.getUserList(pageSize, 0);
          setSkip(0);
          onCompleteEdit();
        }
      }
    }).catch((err) => {
      rootStore.notify("Error: " + err, 'error');
    })
  }

  const onCompleteEdit = () => {
    setCurrentUserId(0);
    setImageName("");
    setFirstName("");
    setSurname("");
    setLoginName("");
    setEmail("");
    setMobile("");
    setPassword("");
    setCPassword("");
    setActivedRole(0);
    setActivedLevel(0);
    setUid("");
    setShowEdit("");
  }

  useEffect(() => {
    if (searchTerm == "") {
      setSkip(0);
      userStore.getUserList(pageSize, 0);
      onCompleteEdit();
    } else {
      const timeOutId = setTimeout(() => userStore.searchUser(searchTerm), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchTerm]);

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showStaffList={false}
          showSeachStudent={true}
          showFilter={false}
          showDomain={false}
          showClassCode={false}
          showYearSemTerm={false}
          showNewsArea={true}
        >
          <title>{t('USER')}</title>
          {userStore.loading && <LoadingPanel />}
          <div className="col-span-4 flex flex-col mx-4 my-2">
            <div className="border rounded-xl shadow-lg">
              <div className={`${headingStyle} pl-4 text-white flex justify-between my-4`}>
                <span className="mt-2 pl-4 uppercase">{t('USER_LIST')}</span>
                <Button
                  className="bg-buttonPrimary text-white text-sm 2xl:text-md"
                  component="span"
                  onClick={onDisplayAddUser}
                >
                  <PersonAddIcon />
                </Button>
              </div>
              <div className='px-10 2xl:px-20' style={{ minHeight: !showEdit ? '80vh' : 'auto' }}>
                <div className="float-right mb-2">
                  <TextField
                    id="input-with-icon-textfield"
                    className={`${classes.textField} py-0`}
                    placeholder={t('SEARCH_USER')}
                    value={searchTerm}
                    onChange={(value) => setSearchTerm(value.target.value as string)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="text-barPrimary">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </div>

                {
                  userStore.loadingList ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <table className="w-full text-center">
                      <thead className="bg-barPrimary">
                        <td className="text-white p-4 text-center">{t('USERNAME')}</td>
                        <td className="text-white p-4 text-center">{t('NAME')}</td>
                        <td className="text-white p-4 text-center">{t('EMAIL')}</td>
                        <td className="text-white p-4 text-center">{t('ACTION')}</td>
                      </thead>
                      <tbody className="border border-t-0">
                        {
                          userStore.userList.data.map((user, index) => (
                            <tr key={`user_${user.id}_${index}`} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                              <td className="border-r text-sm p-2 2xl:text-lg">
                                {user.username}
                              </td>
                              <td className="border-r text-sm p-2 2xl:text-lg">
                                {user.firstName} {user.lastName}
                              </td>
                              <td className="border-r text-sm p-2 2xl:text-lg">
                                {user.email}
                              </td>
                              <td className="text-center p-2">
                                <Button
                                  className="bg-buttonPrimary text-white mx-2 2xl:p-2 text-xs 2xl:text-md mb-2"
                                  component="span"
                                  onClick={() => onDisplayUserDetail(user.id)}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  className="text-white mx-2 2xl:p-2 text-xs 2xl:text-md mb-2"
                                  style={{ background: 'red' }}
                                  component="span"
                                  onClick={() => displayDeleteModal(user.id)}
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                }
                {
                  !userStore.loadingList &&
                  <div className="w-full text-right my-8 flex justify-end">
                    <div className="mr-8 my-2">
                      {skip + pageSize > userStore.userList.total ? userStore.userList.total : skip + pageSize} of {userStore.userList.total}{t('RESULTS')}
                    </div>
                    {
                      searchTerm == "" &&
                      <>
                        <Button
                          className="bg-buttonPrimary text-white mr-4 text-md"
                          component="span"
                          onClick={() => onChangePages("backward")}
                        >
                          <ArrowBackIosIcon fontSize="inherit" />
                        </Button>
                        <Button
                          className="bg-buttonPrimary text-white text-md"
                          component="span"
                          onClick={() => onChangePages("forward")}
                        >
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </Button>
                      </>
                    }

                  </div>
                }
              </div>
            </div>

            {
              showEdit != "" &&
              <VMCard className="my-4"
                titleClassName="my-4"
                titleComponent={
                  <div className={`${headingStyle} pl-4 text-white uppercase`}>
                    {showEdit == "update" ? t('UPDATE_USER') : t('CREATE_USER')}
                  </div>
                }
              >
                <div>
                  <div className="w-40 h-40" style={{ margin: "10px auto" }}>
                    {
                      imageName == "" ? <div className="h-full w-full border object-cover rounded-full"></div>
                        :
                        <img
                          className="h-full w-full border object-cover rounded-full"
                          style={{ margin: "0 auto" }}
                          src={imageName}
                          alt="logo"
                        />
                    }

                  </div>
                  <div className="mb-8 px-20 2xl:px-60">
                    <div className="grid grid-cols-2 w-full mt-8">
                      <div className="mx-4">
                        <TextField
                          disabled={showEdit == "update"}
                          label={t('FIRST_NAME')}
                          variant="outlined"
                          value={firstName}
                          className={`${classes.textField} w-full`}
                          onChange={(value) => {
                            setFirstName(value.target.value);
                            setImageName("");
                            setUid("");
                          }}
                        />
                      </div>

                      <div className="mx-4">
                        <TextField
                          disabled={showEdit == "update"}
                          label={t('LAST_NAME')}
                          variant="outlined"
                          value={surname}
                          className={`${classes.textField} w-full`}
                          onChange={(value) => {
                            setSurname(value.target.value);
                            setImageName("");
                            setUid("");
                          }}
                        />
                      </div>
                    </div>
                    {
                      showEdit == "create" &&
                      <div className="w-full text-right">
                        <Button
                          className="bg-buttonPrimary text-white mt-4 p-2"
                          component="span"
                          onClick={confirmUid}
                        >
                          {t('CHECK_SIMON')}
                        </Button>
                      </div>
                    }
                  </div>

                  {
                    (showEdit == "update" || (showEdit == "create" && uid != "")) &&
                    //(showEdit == "update" || (showEdit == "create")) &&
                    (
                      <div>
                        <div className="grid grid-cols-2 px-20 2xl:px-60 w-full">
                          <div className="mx-4">
                            <TextField
                              label={t('USERNAME')}
                              variant="outlined"
                              value={loginName}
                              className={`${classes.textField} w-full my-2`}
                              onChange={(value) => setLoginName(value.target.value)}
                            />
                            <TextField
                              label={t('MOBILE')}
                              variant="outlined"
                              value={mobile}
                              className={`${classes.textField} w-full my-2`}
                              onChange={(value) => setMobile(value.target.value)}
                            />
                            {/* <TextField
                              label={t('PASSWORD')}
                              type="password"
                              variant="outlined"
                              value={password}
                              className={`${classes.textField} w-full my-2`}
                              onChange={(value) => setPassword(value.target.value)}
                            />
                            <TextField
                              label={t('CONFIRM_PASSWORD')}
                              type="password"
                              variant="outlined"
                              value={cPassword}
                              className={`${classes.textField} w-full my-2`}
                              onChange={(value) => setCPassword(value.target.value)}
                            /> */}
                          </div>

                          <div className="mx-4">
                            <TextField
                              // disabled={true}
                              label={t('EMAIL')}
                              variant="outlined"
                              value={email}
                              className={`${classes.textField} w-full my-2`}
                              onChange={(value) => setEmail(value.target.value)}
                            />
                            {
                              showEdit == "create" && activeCollege != 0 ?

                                collegeStore.loadingPermissionDropdowns ? <div className="col-span-5 w-full text-center p-10"><CircularProgress className="text-barPrimary" /></div>
                                  :
                                  <>
                                    {
                                      collegeStore.permissionList.length > 0 ?
                                        <>
                                          <div className=
                                            "text-buttonPrimary my-2"
                                          >
                                            {t('USER_ROLE')}
                                          </div>
                                          <div className="col-span-5 lg:col-span-3 mb-4">
                                            <Select
                                              labelId="demo-simple-select-label"
                                              value={activedRole}
                                              onChange={onChangeActivedRole}
                                              id="demo-simple-select"
                                              className="bg-background w-full h-full px-4"
                                            >
                                              {
                                                collegeStore.permissionList.map((college) => (
                                                  <MenuItem value={college.id}>{college.name}</MenuItem>
                                                ))
                                              }
                                            </Select>
                                          </div>
                                          <div className=
                                            "text-buttonPrimary my-2"
                                          >
                                            {t('PERMISSION_LEVEL')}
                                          </div>
                                          <div className="col-span-5 lg:col-span-3">
                                            <Select
                                              labelId="demo-simple-select-label"
                                              value={activedLevel}
                                              onChange={onChangeActivedLevel}
                                              id="demo-simple-select"
                                              className="bg-background w-full px-4"
                                            >
                                              {
                                                collegeStore.permissionList.filter((p) => p.id === activedRole).map((p) => (
                                                  p.permissionStrategy.map((ps) => (
                                                    <MenuItem value={ps.id}>{ps.name}</MenuItem>
                                                  ))
                                                ))
                                              }
                                            </Select>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <div className="col-span-2" />
                                          <div className="col-span-3">{t('NO_PERMISSION_FOUND')}!</div>
                                        </>
                                    }
                                  </>

                                : null
                            }
                          </div>
                        </div>
                        <div className="text-right w-full my-8 px-20">
                          {
                            showEdit == "update" &&
                            <Button
                              className="bg-buttonPrimary text-white"
                              component="span"
                              onClick={() => onDisplayUserDetail(currentUserId, true)}
                            >
                              {t('RESET_ALL')}
                            </Button>
                          }

                          <Button
                            className="bg-buttonPrimary text-white ml-4"
                            component="span"
                            onClick={showEdit == "create" ? createUser : updateUser}
                          >
                            {t('SAVE')}
                          </Button>
                        </div>
                      </div>
                    )
                  }

                </div>
              </VMCard>
            }

            {/* Bottom Section */}
            {
              showEdit == "update" &&
              <VMCard
                titleClassName="my-4"
                titleComponent={
                  <div className={headingStyle}>
                    {t('USER_PERMISSION_SETTING')}
                  </div>
                }>
                <div className="">
                  <div className='px-8'>
                    {t('PERMISSION_FOR_USER')}: {userStore.selectedUserInfo.firstName + " " + userStore.selectedUserInfo.lastName}
                  </div>
                  {collegeStore.permissionList.length > 0 &&
                    // <PermissionSetting
                    //   role={collegeStore.permissionList}
                    //   collegeId={activeCollege}
                    //   id={0}
                    //   callback={() => { console.log() }}
                    //   callbackAfterUpdated={onCompleteEdit}
                    //   userRequired={true}
                    // />
                    <PermissionTemplate
                      forUser={true}
                      actionAfterSave={onCompleteEdit}
                    />}
                </div>
              </VMCard>
            }
          </div>

          {
            showModal &&
            <CustomisedModal showModal={showModal}>
              <div className={`${MODAL_WINDOW} p-10`} style={{ transform: 'translate(-50%, -50%)' }}>
                <div className="text-xl mb-8">
                  <ErrorOutlineIcon className="text-3xl mr-4 mb" />
                  <span className="pt-10">{t('DELETE_USER_CHECK')}?</span>
                </div>
                <div className="w-full text-right">
                  <Button
                    className="bg-buttonPrimary text-white mx-2 p-2"
                    component="span"
                    onClick={() => setShowModal(false)}
                  >
                    {t('CANCEL')}
                  </Button>

                  <Button
                    className="text-white mx-2 p-2"
                    style={{ background: 'red' }}
                    component="span"
                    onClick={onDeleteUser}
                  >
                    {t('DELETE')}
                  </Button>
                </div>
              </div>
            </CustomisedModal>
          }
        </Layout >
      }
    </>
  )
})

export default UserPage;
